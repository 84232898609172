<!-- <div class="login_global">
  <div class="login_content">
    <div class="login_col1">
      <div class="login_col1_global">
        <div class="login_comp_type1">
          <a href="/"
            ><img src="../assets/logo/logo_ca_eitafibre.png" alt="login logo"
          /></a>
        </div>
        <div class="login_comp_type1">
          <div class="login_comp_type2">
            <div class="login_comp_type1">
              <h1>Réinitialiser votre de mot de passe</h1>
            </div>
            <div class="login_comp_type1">
              <h4>
                Merci de saisir votre nouveau mot de passe et le confirmer.
              </h4>
            </div>
          </div>
        </div>

        <div class="login_comp_type1">
          <form
            [formGroup]="resetForm"
            (ngSubmit)="resetPassword()"
            class="has-validation"
          >
            <div class="form-group">
              <label for="password" class="form-label required"
                >Nouveau mot de passe</label
              >
              <div class="input-group">
                <input
                  [type]="fieldTextTypePassword ? 'text' : 'password'"
                  class="form-control mb-0"
                  id="resetPasswordPassword"
                  aria-describedby="resetPasswordPassword"
                  placeholder="Entrez votre nouveau mot de passe"
                  formControlName="password"
                  [ngClass]="{
                    'is-invalid': f['password'].touched && f['password'].invalid
                  }"
                />
                <span
                  class="input-group-text"
                  (click)="toggleFieldTextTypePassword()"
                >
                  <img
                    *ngIf="
                      f['password'].value.length > 0 && !fieldTextTypePassword
                    "
                    src="../assets/icon/icon_eye.svg"
                    alt="icon_eye"
                  />
                  <img
                    *ngIf="
                      f['password'].value.length > 0 && fieldTextTypePassword
                    "
                    src="../assets/icon/icon_eye_slash.svg"
                    alt="icon_eye_slash"
                  />
                </span>
                <div
                  class="invalid-feedback"
                  *ngIf="f['password'].touched && f['password'].invalid"
                >
                  <strong *ngIf="f['password'].hasError('required')">
                    * Le mot de passe est requis.
                  </strong>
                  <strong *ngIf="f['password'].hasError('minlength')">
                    * Le mot de passe doit contenir au moins 6 caractères.
                  </strong>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="confirmPassword" class="form-label required"
                >Confirmez le nouveau mot de passe</label
              >
              <div class="input-group">
                <input
                  [type]="fieldTextTypeConfirmPassword ? 'text' : 'password'"
                  class="form-control"
                  id="resetPasswordConfirmPassword"
                  aria-describedby="resetPasswordConfirmPassword"
                  placeholder="Confirmez votre nouveau mot de passe"
                  formControlName="confirmPassword"
                  [ngClass]="{
                    'is-invalid':
                      f['confirmPassword'].touched &&
                      f['confirmPassword'].invalid
                  }"
                />
                <span
                  class="input-group-text"
                  (click)="toggleFieldTextTypeConfirmPassword()"
                >
                  <img
                    *ngIf="
                      f['confirmPassword'].value.length > 0 &&
                      !fieldTextTypeConfirmPassword
                    "
                    src="../assets/icon/icon_eye.svg"
                    alt="icon_eye"
                  />
                  <img
                    *ngIf="
                      f['confirmPassword'].value.length > 0 &&
                      fieldTextTypeConfirmPassword
                    "
                    src="../assets/icon/icon_eye_slash.svg"
                    alt="icon_eye_slash"
                  />
                </span>
                <div
                  class="invalid-feedback"
                  *ngIf="
                    f['confirmPassword'].touched && f['confirmPassword'].invalid
                  "
                >
                  <strong *ngIf="f['confirmPassword'].hasError('required')">
                    * La confirmation du mot de passe est requise.
                  </strong>
                  <strong
                    *ngIf="
                      resetForm.hasError('mismatch') &&
                      f['confirmPassword'].value.length > 0
                    "
                  >
                    * Les mots de passe ne correspondent pas.
                  </strong>
                </div>
              </div>
              <div
                [ngClass]="{
                  captcha_invalid: !resetForm.valid
                }"
              >
                <div class="captcha">
                  <re-captcha
                    (resolved)="resolved($event)"
                    (errored)="onError($event)"
                    errorMode="handled"
                    [siteKey]="siteKey"
                  ></re-captcha>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="login_button">
                <button
                  class="btn btn-primary btn-block"
                  [disabled]="!captchaValid"
                >
                  Réinitialiser
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="login_col2">
      <img
        src="../assets/background/login_background.jpg"
        alt="Login background"
      />
    </div>
  </div>
</div> -->

<div class="w-100 h-100 d-flex justify-content-center align-items-center gradient-background" style="font-family:Inter-Regular">
  <div class="h-75 d-flex flex-column justify-content-start" style="width:550px; background: #fff;border-radius: 5px;" >
    <div class="w-100 d-flex justify-content-center p-3">
      <img width="60px" height="60px" src="../assets/logo/logo_ca_eitafibre.png" alt="login logo" />
    </div>
    <div class="p-3 text-center" style="color:var(--c3);">
      <strong>Réinitialiser votre de mot de passe</strong>
    </div>
    <div class="p-3 text-center" style="color:var(--c3);">
      <span>Merci de saisir votre nouveau mot de passe et le confirmer</span>
    </div>
    <form class="w-100 h-50 mt-5 d-flex align-items-center justify-content-between flex-column"
    [formGroup]="resetForm"
    (ngSubmit)="resetPassword()"
    >
    <div class="d-flex d-flex flex-row w-75 align-items-center input-box">
      <input class="w-100" [type]="fieldTextTypePassword ? 'text' : 'password'" id="password" formControlName="password" placeholder="Entrez votre nouveau mot de passe"  required
      required/>
      <div class="pointer" style="color: #d0d0d0" (click)="toggleFieldTextTypePassword()">
        <i *ngIf="fieldTextTypePassword" class="fi fi-rs-crossed-eye pe-2 pt-1"></i>
        <i *ngIf="!fieldTextTypePassword" class="fi fi-rs-eye pe-2 pt-1"></i>
      </div>
    </div>
      <span class="w-75" *ngIf=" f['password'].touched && f['password'].invalid" style="color:rgb(255, 97, 97);font-size:small; position: relative; left:0;"> * Le mot de passe est requis.</span>
      <div class="d-flex d-flex flex-row w-75 align-items-center input-box">
        <input class="w-100" [type]="fieldTextTypeConfirmPassword ? 'text' : 'password'" id="confirmPassword" formControlName="confirmPassword" placeholder="Confirmez votre nouveau mot de passe"  required
        required/>
        <div class="pointer" style="color: #d0d0d0" (click)="toggleFieldTextTypeConfirmPassword()">
          <i *ngIf="fieldTextTypeConfirmPassword" class="fi fi-rs-crossed-eye pe-2 pt-1"></i>
          <i *ngIf="!fieldTextTypeConfirmPassword" class="fi fi-rs-eye pe-2 pt-1"></i>
        </div>
      </div>
      <span class="w-75"*ngIf="f['confirmPassword'].hasError('required')
    " style="color:rgb(255, 97, 97);font-size:small; position: relative; left:0;">* La confirmation du mot de passe est requise.</span>
                      <span
                      style="color:rgb(255, 97, 97);font-size:small; position: relative; left:0;"
                      *ngIf="
                        resetForm.hasError('mismatch') &&
                        f['confirmPassword'].value.length > 0
                      "
                    >
                      * Les mots de passe ne correspondent pas.
                    </span>
      <div class="w-75">
        <div [ngClass]="{ captcha_invalid: !resetForm.valid}">
          <re-captcha class="captcha"
            (resolved)="resolved($event)"
            (errored)="onError($event)"
            errorMode="handled"
              [siteKey]="siteKey"
          ></re-captcha>
        </div>
      </div>
      <div class="w-100 d-flex justify-content-center">
        <button class="w-75 con pointer" [disabled]="!captchaValid">
           <small>Réinitialiser</small> 
        </button>
      </div>
    </form>
  </div>
</div>
