<div class="w-100 h-100" style="position: absolute">
  <div class="sidebar "style="position: fixed;top:0;z-index: 200 ">
    <div  class="w-100 h-100 d-flex  justify-content-between flex-column p-2 " style="font-size: larger;color: rgb(88, 88, 88);">
      <div class="w-100 d-flex flex-column" >
        <div class="p-2 logo">
          <a  href="/dashboard"
            ><img src="../assets/logo/logo_ca_eitafibre.png" alt=""
          /></a>
        </div>
        <div class="mt-4 mb-1 pointer">        
          <a  class="p-1  ps-2 d-flex"  [routerLink]="['/dashboard']"  routerLinkActive="active" >
            <i class="fi fi-rr-home icon"></i>
          <span class="ps-2 sidebar-element">Tableau de bord</span>
        </a>
        </div>
        <div class="mb-1 pointer ">
          <a class="p-1 ps-2 d-flex" [routerLink]="['/inters-free']" routerLinkActive="active" > 
            <i class="fi fi-rr-tools icon"></i>
            <span class="ps-2 sidebar-element"  >Intervention free</span>
        </a>
        </div>
        <div class="mb-1 pointer ">
          <a class="p-1 ps-2 d-flex" [routerLink]="['/itineraires-free']" routerLinkActive="active" > 
            <i class="fi fi-rr-route icon"></i>
            <span class="ps-2 sidebar-element" >Iteneraire free</span>
        </a>
        </div>
        <div class="mb-1 pointer ">
          <a class="p-1 ps-2 d-flex" [routerLink]="['/techs']" routerLinkActive="active" > 
            <i class="fi fi-rr-user-helmet-safety icon" ></i>
            <span class="ps-2 sidebar-element" >Techniciens</span>
        </a>
        </div>
        <div  class="mb-1 pointer">
          <a class="p-1 ps-2 d-flex" [routerLink]="['/users']" routerLinkActive="active" >
            <i class="fi fi-rr-circle-user icon" ></i>
            <span class="ps-2 sidebar-element" >Utilisateurs</span>
          </a>
        </div>
        <div  class="mb-1 pointer">
          <a class="p-1 ps-2 d-flex"  data-bs-toggle="collapse"   href="#collapseSpie" role="button" aria-expanded="true">
            <i class="fi fi-rr-handshake icon"></i>
            <span class="ps-2 sidebar-element" >SPIE <i class="fi fi-rr-angle-small-down"></i></span>
          </a>
          <div class="collapse" id="collapseSpie">
            <div>
              <a class="mt-2 d-flex" [routerLink]="['/spie/documents']"  routerLinkActive="active" >
                <i class="fi fi-rr-file-alt icon"></i>
                <span class="ps-3 sidebar-element">Projet St Melo</span>
              </a>
            </div>
          </div>
        </div>
  </div>
  <div  class="w-100  pointer" style="position: relative; bottom:1rem;" >
    <div  class="mb-1 pointer">
      <a class="p-1 ps-2  d-flex" [routerLink]="['/in-development']" routerLinkActive="active" >
        <i class="fi fi-rr-life-ring icon"></i>
        <span class="ps-2  sidebar-element">Support</span>
      </a>
    </div>
    <div  class="pb-1 pointer">
      <a class="p-1 ps-2 d-flex" [routerLink]="['/in-development']" routerLinkActive="active" >
        <i class="fi fi-rr-settings icon"></i>
        <span class="ps-2  sidebar-element">Paramétres</span>
      </a>
    </div>
    <div  class="mb-1 pointer">
      <a class="p-1 ps-2 d-flex" (click)="signOut()" >
        <i class="fi fi-rr-exit icon"></i>
        <span class="ps-2 sidebar-element">Déconnecter</span>
      </a>
    </div>
  </div>
      </div>
        
  </div>
  <div class="w-100 navbar_global d-flex justify-content-end align-items-center" style= "height: 60px;z-index: 99;position: fixed;top:0; background-color:rgb(250, 250, 250)">
   
    <div class="d-flex align-items-center" style="font-size: 80%">
      <strong class="me-3 p-1 " style="color:var(--g8);font-size: 90%">
        <i class="fi fi-br-calendar-clock me-2"></i>
        <span>{{currentDateAndTime | date:'MMMM d, y'}}</span>
      </strong>
      <div class="me-3 p-1 pe-2 ps-4" style="background-color: var(--c5);border-radius: 20px;">
        <span class="pe-2" style="color: var(--g8);">{{username}}</span>
        <img src="assets/icon/male-profile.png" width="30px" height="30px">
    </div>
    </div>
  </div>
</div>

