import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { HomeComponent } from './home/home.component';
import { AuthGuardService } from './core/guards/auth-guard.service'; 
import { ListeTechsComponent } from './techs/liste-techs/liste-techs.component'; 
import { LoginComponent } from './connexion/login/login.component';
import { ForgotPasswordComponent } from './connexion/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './connexion/reset-password/reset-password.component';
import { ListeFreeComponent } from './inters-free/liste-free/liste-free.component';
import { IteneraireFreeComponent } from './inters-free/iteneraire-free/iteneraire-free.component';
import { ListeUsersComponent } from './users/liste-users/liste-users.component';
import { PlanningComponent } from './planning/planning.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ForbiddenPageComponent } from './shared/components/forbidden-page/forbidden-page.component';
import { NotFoundPageComponent } from './shared/components/not-found-page/not-found-page.component';
import { RoleGuard } from './core/guards/role.guard';
import { InDevelopmentComponent } from './shared/components/in-development/in-development.component';


const routes: Routes = [
  {path:'dashboard',component:DashboardComponent, canActivate: [AuthGuardService,RoleGuard], data: {roles: ['ROLE_SU','ROLE_CA']}},
  // {path: 'inters-realisees-bouygues', component: ListeRealiseesComponent,  canActivate: [AuthGuardService]},
  // {path: 'inters-facturees-bouygues', component: ListeFactureesComponent,  canActivate: [AuthGuardService]},
  // {path: 'comparaison-bouygues', component: ComparisonComponent,  canActivate: [AuthGuardService]},
  { path: 'inters-free', component: ListeFreeComponent, canActivate: [AuthGuardService,RoleGuard], data: {roles: ['ROLE_SU','ROLE_CA']} },
  { path: 'itineraires-free', component: IteneraireFreeComponent, canActivate: [AuthGuardService,RoleGuard], data: {roles: ['ROLE_SU','ROLE_CA']} },
  { path: 'techs', component: ListeTechsComponent, canActivate: [AuthGuardService,RoleGuard], data: {roles: ['ROLE_SU','ROLE_CA']} },
  { path: 'users', component: ListeUsersComponent, canActivate: [AuthGuardService,RoleGuard], data: {roles: ['ROLE_SU','ROLE_CA']} },
  { path: '', component: LoginComponent },
  { path: 'forgotPassword', component: ForgotPasswordComponent },
  { path: 'resetPassword', component: ResetPasswordComponent },
  { path: 'planning', component: PlanningComponent, canActivate: [AuthGuardService,RoleGuard], data: {roles: ['ROLE_TECH']} },
  {path: 'forbidden-access', component: ForbiddenPageComponent},
  {path: 'page-not-found', component: NotFoundPageComponent},
  {path:'in-development',component: InDevelopmentComponent},
  { path: 'spie', loadChildren: () => import('./spie/spie.module').then(m => m.SpieModule) , canActivate: [AuthGuardService]},
  { path: '**', redirectTo: '/page-not-found', pathMatch: 'full' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
