<div class="w-100 h-100 d-flex justify-content-center align-items-center gradient-background" style="font-family:Inter-Regular">
  <div class="h-75 d-flex flex-column justify-content-start" style="width:550px; background: #fff;border-radius: 5px;" >
    <div class="w-100 d-flex justify-content-center p-3">
      <img width="60px" height="60px" src="../assets/logo/logo_ca_eitafibre.png" alt="login logo" />
    </div>
    <div class="p-3 text-center" style="color:var(--c3);">
      <strong>Mot de passe oublié !</strong>
    </div>
    <div class="p-3  w-100 d-flex justify-content-center" style="color:var(--c3);">
      <div class="text-center w-75">
        <span>Aprés avoir envoyé ce formulaire vous recevrez un email avec un lien vers une page vous permettant de réinitialiser votre mot passe.</span>
      </div>
    </div>
    <form class="w-100 h-50 mt-5 d-flex align-items-center justify-content-between flex-column"
    [formGroup]="forgotPasswordForm"
    (ngSubmit)="sendMail()"
    *ngIf="appState=='INIT'"
    >
      <div class="d-flex d-flex flex-column w-75 input-box">
        <input                 type="email"
        class="form-control"
        id="forgotPasswordEmail"
        aria-describedby="forgotPasswordEmail"
        placeholder="example@eitafibre.fr"
        formControlName="email" placeholder="Email" 
        [disabled]="loading"
        required/>
      </div>
      <div class="w-100 d-flex align-items-center flex-column">
        <small class="pb-4 x-cl-danger" [hidden]="!forgotPasswordForm.controls['email'].errors || !forgotPasswordForm.controls['email'].touched">Merci de saisir votre email correctement. </small>
        <button class="w-75 con d-flex justify-content-center" [ngClass]="{'pointer': !forgotPasswordForm.invalid}" [disabled]="forgotPasswordForm.invalid || loading">
           <small>Envoyer</small>
            <span [hidden]="!loading" class="spinner-square ms-2">
              <div class="square-1 square"></div>
              <div class="square-2 square"></div>
              <div class="square-3 square"></div>
            </span>
        </button>
      </div>
    </form>
    <div class="w-100 h-50 mt-5 d-flex justify-content-center align-items-center success-message" *ngIf="appState=='SUCCESS'">
      <div class=" w-75 d-flex justify-content-center align-items-center flex-column">
        <i class="fi fi-tr-check-circle x-cl-success" style="font-size: 5rem;"></i>
        <small class="text-center" >Veuillez vérifier votre e-mail pour réinitialiser votre mot de passe. Merci!</small>
      </div>
    </div>
    <div class="w-100 h-50 mt-5 d-flex justify-content-center align-items-center success-message" *ngIf="appState=='ERROR'">
      <div class=" w-75 d-flex justify-content-center align-items-center flex-column">
        <i class="fi fi-rs-exclamation x-cl-danger" style="font-size: 5rem;"></i>
        <small class="text-center" >Une erreur s'est produite lors de l'envoi du formulaire. Veuillez réessayer plus tard. Merci.</small>
      </div>
    </div>
    <div class="w-100 h-25 d-flex align-items-center flex-column justify-content-around">
      <div class="w-75 d-flex justify-content-center">
        <small  class="pointer" routerLink="/" style="color:var(--c3)"><span class="pe-3">&#8592;</span>Sign in!</small>
      </div>
    </div>
  </div>
</div>
